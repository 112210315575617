import { createStore, compose, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { browserHistory } from "react-router";
import { routerMiddleware } from "react-router-redux";
import rootReducer from "Reducers";
import { googleAnalytics } from "./reactGAMiddlewares";

const middlewares = [thunk, routerMiddleware(browserHistory), googleAnalytics];

function configureStoreProd(initialState) {
  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose; // add support for Redux dev tools

  const store = createStore(
    rootReducer(),
    initialState,
    composeEnhancers(applyMiddleware(...middlewares))
  );
  store.asyncReducers = {};
  return store;
}

let configureStore;

if (process.env.NODE_ENV === "production") {
  configureStore = configureStoreProd;
} else {
  const configureStoreDev = initialState => {
    const devMiddlewares = [
      // Redux middleware that spits an error on you when you try to mutate your state either inside a dispatch or between dispatches.
      require("redux-immutable-state-invariant")(),

      // thunk middleware can also accept an extra argument to be passed to each thunk action
      // https://github.com/gaearon/redux-thunk#injecting-a-custom-argument
      ...middlewares
    ];

    const composeEnhancers =
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose; // add support for Redux dev tools
    const store = createStore(
      rootReducer(),
      initialState,
      composeEnhancers(applyMiddleware(...devMiddlewares))
    );

    store.asyncReducers = {};

    if (module.hot) {
      // Enable Webpack hot module replacement for reducers
      module.hot.accept("Reducers", () => {
        const nextReducer = require("Reducers").default; // eslint-disable-line global-require
        store.replaceReducer(nextReducer(store.asyncReducer));
      });
    }

    return store;
  };
  configureStore = configureStoreDev;
}

export default configureStore;
