import Raven from "raven-js";

if (process.env.NODE_ENV === "production") {
  const DSN = "https://8051dcccac49481f974eba00e1e1a838@sentry.io/1180480";
  Raven.config(DSN).install();
}

function logException(ex, context) {
  if (process.env.NODE_ENV === "production") {
    Raven.captureException(ex, {
      extra: context
    });
  }
  /*eslint no-console:0*/
  window.console && console.error && console.error(ex);
}

export default logException;
