import styled from "styled-components";

export default styled.h1`
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-size: 32px;
  line-height: 32px;
  color: #302f45;
  margin: 0;
  &:first-letter {
    text-transform: uppercase;
  }
`;
