// Boilerplate source https://github.com/coryhouse/react-slingshot
import React from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import { Router, browserHistory } from "react-router";
import { syncHistoryWithStore } from "react-router-redux";
import { IntlProvider, addLocaleData } from "react-intl";
import ReactGA from "react-ga";
import TagManager from "react-gtm-module";
import en from "react-intl/locale-data/en";
import fr from "react-intl/locale-data/fr";
import AppErrorBoundary from "Components/AppErrorBoundary";

import createRoutes, { loadRoute } from "./routes";
import configureStore from "./store/configureStore";
import { injectReducer } from "Reducers";

require("./favicon.ico"); // Tell webpack to import favicon.ico
require("babel-polyfill");
require("codemirror/lib/codemirror.css");
import "./global-style";
import "react-custom-scroll/dist/customScroll.css";
import "platformsh-user-widget/dist/user-widget.css";
import "react-toggle-switch/dist/css/switch.min.css";
import "Components/filter/filter.css";

if (process.env.VENDOR_URL) {
  require("Vendor_resources/ui.css");
}

addLocaleData([...en, ...fr]);

// Define user's language. Different browsers have the user locale defined
// on different fields on the `navigator` object, so we make sure to account
// for these different by checking all of them
const userLanguage =
  (navigator.languages && navigator.languages[0]) ||
  navigator.language ||
  navigator.userLanguage;
// Split locales with a region code
const languageWithoutRegionCode = userLanguage.toLowerCase().split(/[_-]+/)[0];
const supportedLanguages = ["en", "fr"];
let language;
if (supportedLanguages.indexOf(languageWithoutRegionCode) > -1) {
  language = userLanguage;
} else {
  language = "en-US";
}

// Load only the language that we need
let localeData;
try {
  localeData = require(`./locales/${languageWithoutRegionCode}.json`);
} catch (err) {
  // If the translation not exist
  localeData = require(`./locales/en.json`);
}

const store = configureStore();
createRoutes(store);

// Set up the router, wrapping all Routes in the App component
const rootRoute = {
  childRoutes: createRoutes(store),
  getComponent: (nextState, cb) => {
    const importModules = Promise.all([
      import("Reducers/app"),
      import("Reducers/app/theme"),
      import("Reducers/organization"),
      import("Reducers/project"),
      import("Containers/App")
    ]);

    const renderRoute = loadRoute(cb);

    importModules.then(
      ([
        appReducer,
        themeReducer,
        organizationReducer,
        projectReducer,
        component
      ]) => {
        injectReducer(store, {
          key: "organization",
          reducer: organizationReducer.default
        });
        injectReducer(store, { key: "app", reducer: appReducer.default });
        injectReducer(store, { key: "theme", reducer: themeReducer.default });
        injectReducer(store, {
          key: "project",
          reducer: projectReducer.default
        });
        renderRoute(component);
      }
    );
  }
};

// Create an enhanced history that syncs navigation events with the store
const history = syncHistoryWithStore(browserHistory, store);

const fallbackContainer = document.getElementById("fallback");
setTimeout(() => {
  const appContainer = document.getElementById("app");
  if (fallbackContainer && appContainer.hasChildNodes()) {
    fallbackContainer.parentNode
      ? fallbackContainer.parentNode.removeChild(fallbackContainer)
      : "";
  }
}, 1000);

if (process.env.PLATFORM_TREE_ID) {
  //  eslint-disable-next-line no-console
  console.log(`Build ID: %c${process.env.PLATFORM_TREE_ID}`, "color: green");
}

// Initialize Google Analytics
let logRouteToGA;
if (process.env.GA_PROPERTY_ID) {
  ReactGA.initialize(process.env.GA_PROPERTY_ID, {
    titleCase: false
  });

  logRouteToGA = () => {
    ReactGA.pageview(window.location.pathname);
  };
}

if (process.env.NODE_ENV === "production") {
  const tagManagerArgs = {
    gtmId: "GTM-MR3BJL"
  };
  TagManager.initialize(tagManagerArgs);
}

render(
  <AppErrorBoundary>
    <Provider store={store}>
      <IntlProvider locale={language} messages={localeData}>
        <Router
          onUpdate={process.env.GA_PROPERTY_ID ? logRouteToGA : null}
          history={history}
          routes={rootRoute}
        />
      </IntlProvider>
    </Provider>
  </AppErrorBoundary>,
  document.getElementById("app")
);
