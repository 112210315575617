import React from "react";
import PropTypes from "prop-types";

const CopyIcon = ({ color = "#000" }) => (
  <svg
    className="icon-copy"
    width="19px"
    height="22px"
    viewBox="0 0 19 22"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    alt="Copy"
  >
    <defs />
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        transform="translate(-819.000000, -1302.000000)"
        fill={color}
        fillRule="nonzero"
      >
        <g transform="translate(817.000000, 1301.000000)">
          <path d="M16,1 L4,1 C2.9,1 2,1.9 2,3 L2,17 L4,17 L4,3 L16,3 L16,1 Z M19,5 L8,5 C6.9,5 6,5.9 6,7 L6,21 C6,22.1 6.9,23 8,23 L19,23 C20.1,23 21,22.1 21,21 L21,7 C21,5.9 20.1,5 19,5 Z M19,21 L8,21 L8,7 L19,7 L19,21 Z" />
        </g>
      </g>
    </g>
  </svg>
);

CopyIcon.propTypes = {
  color: PropTypes.string
};

export default CopyIcon;
