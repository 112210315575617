import { injectReducer } from "Reducers";
import { browserHistory } from "react-router";

/*function errorLoading(err) {
 console.error('Dynamic page loading failed', err); //eslint-disable-line
}*/

export function loadRoute(cb) {
  return module => cb(null, module.default);
}

export default function createRoutes(store) {
  const routeDefinitions = [
    {
      path: "(:organizationId)/create-project(/:step)",
      name: "setup",
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import("Reducers/project/region"),
          import("Reducers/project/setup"),
          import("./pages/Setup")
        ]);

        const renderRoute = loadRoute(cb);

        importModules.then(([region, setupReducer, component]) => {
          injectReducer(store, { key: "region", reducer: region.default });
          injectReducer(store, { key: "setup", reducer: setupReducer.default });
          renderRoute(component);
        });
      }
    },
    {
      path: "/all-activity",
      name: "all-activity",
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import("Reducers/project"),
          import("./pages/AllActivity")
        ]);

        const renderRoute = loadRoute(cb);

        importModules.then(([reducer, component]) => {
          injectReducer(store, { key: "project", reducer: reducer.default });
          renderRoute(component);
        });
      }
    },
    {
      path: "/",
      name: "projects",
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import("Reducers/project"),
          import("Reducers/organization"),
          import("./pages/MyProjects")
        ]);

        const renderRoute = loadRoute(cb);

        importModules.then(
          ([projectReducer, organizationReducer, component]) => {
            injectReducer(store, {
              key: "project",
              reducer: projectReducer.default
            });
            injectReducer(store, {
              key: "organization",
              reducer: organizationReducer.default
            });
            renderRoute(component);
          }
        );
      }
    },
    {
      path: "/projects",
      name: "projects-redirect",
      getComponent() {
        browserHistory.push("/");
      }
    },
    /*{
      path: "/organizations",
      name: "organizations",
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import("Reducers/project"),
          import("./pages/MyOrganizations")
        ]);

        const renderRoute = loadRoute(cb);

        importModules.then(([reducer, component]) => {
          injectReducer(store, { key: "project", reducer: reducer.default });
          renderRoute(component);
        });
      }
    },
    {
      path: "/organizations/activity",
      name: "organizations-activity",
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import("Reducers/project"),
          import("./pages/OrganizationsActivity")
        ]);

        const renderRoute = loadRoute(cb);

        importModules.then(([reducer, component]) => {
          injectReducer(store, { key: "project", reducer: reducer.default });
          renderRoute(component);
        });
      }
    },
    {
      path: "/organizations/new",
      name: "organizations-new",
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import("Reducers/project"),
          import("./pages/OrganizationNew")
        ]);

        const renderRoute = loadRoute(cb);

        importModules.then(([reducer, component]) => {
          injectReducer(store, { key: "project", reducer: reducer.default });
          renderRoute(component);
        });
      }
    },
    {
      path: "/(:organizationId)",
      name: "organization",
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import("Reducers/project"),
          import("./pages/organization/Detail")
        ]);

        const renderRoute = loadRoute(cb);

        importModules.then(([reducer, component]) => {
          injectReducer(store, { key: "project", reducer: reducer.default });
          renderRoute(component);
        });
      }
    },
    {
      path: "/:organizationId/activity",
      name: "organization-activity",
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import("Reducers/project"),
          import("./pages/organization/Activity")
        ]);

        const renderRoute = loadRoute(cb);

        importModules.then(([reducer, component]) => {
          injectReducer(store, { key: "project", reducer: reducer.default });
          renderRoute(component);
        });
      }
    },
    {
      path: "/:organizationId/teams",
      name: "organization-teams",
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import("Reducers/organization/team"),
          import("Reducers/project"),
          import("Reducers/user"),
          import("./pages/organization/Teams")
        ]);

        const renderRoute = loadRoute(cb);

        importModules.then(
          ([teamReducer, projectReducer, userReducer, component]) => {
            injectReducer(store, { key: "team", reducer: teamReducer.default });
            injectReducer(store, {
              key: "project",
              reducer: projectReducer.default
            });
            injectReducer(store, {
              key: "user",
              reducer: userReducer.default
            });
            renderRoute(component);
          }
        );
      },
      childRoutes: [
        {
          path: "new",
          name: "teams-new",
          getComponent(nextState, cb) {
            const importModules = Promise.all([
              import("Reducers/organization/team"),
              import("./pages/organization/team/New")
            ]);

            const renderRoute = loadRoute(cb);

            importModules.then(([reducer, component]) => {
              injectReducer(store, {
                key: "team",
                reducer: reducer.default
              });
              renderRoute(component);
            });
          }
        },
        {
          path: ":teamId/members/new",
          name: "teams-members",
          getComponent(nextState, cb) {
            const importModules = Promise.all([
              import("Reducers/organization/team"),
              import("./pages/organization/team/members/New")
            ]);

            const renderRoute = loadRoute(cb);

            importModules.then(([reducer, component]) => {
              injectReducer(store, {
                key: "team",
                reducer: reducer.default
              });
              renderRoute(component);
            });
          }
        }
      ]
    },
    {
      path: "/:organizationId/members",
      name: "organizationMembers",
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import("Reducers/organization"),
          import("Reducers/user"),
          import("./pages/organization/Members")
        ]);

        const renderRoute = loadRoute(cb);

        importModules.then(([organizationReducer, userReducer, component]) => {
          injectReducer(store, {
            key: "organization",
            reducer: organizationReducer.default
          });
          injectReducer(store, {
            key: "user",
            reducer: userReducer.default
          });
          renderRoute(component);
        });
      }
    },
    {
      path: "/:organizationId/externals",
      name: "organizationMembers",
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import("Reducers/organization"),
          import("./pages/organization/Externals")
        ]);

        const renderRoute = loadRoute(cb);

        importModules.then(([reducer, component]) => {
          injectReducer(store, {
            key: "organization",
            reducer: reducer.default
          });
          renderRoute(component);
        });
      }
    },
    {
      path: "/:organizationId/teams/:teamId",
      name: "organization-team",
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import("Reducers/organization/team"),
          import("./pages/organization/team/Detail")
        ]);

        const renderRoute = loadRoute(cb);

        importModules.then(([reducer, component]) => {
          injectReducer(store, { key: "team", reducer: reducer.default });
          renderRoute(component);
        });
      }
    },
    {
      path: "/:organizationId/teams/:teamId/settings",
      name: "organization-team-settings",
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import("Reducers/organization/team"),
          import("./pages/organization/team/Settings")
        ]);

        const renderRoute = loadRoute(cb);

        importModules.then(([reducer, component]) => {
          injectReducer(store, { key: "team", reducer: reducer.default });
          renderRoute(component);
        });
      }
    },*/
    {
      path: "/:organizationId/settings",
      name: "organization-settings",
      disabled: true,
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import("Reducers/app"),
          import("Reducers/user"),
          import("./pages/organization/settings")
        ]);

        const renderRoute = loadRoute(cb);

        importModules.then(([reducerApp, reducerUser, component]) => {
          injectReducer(store, { key: "app", reducer: reducerApp.default });
          injectReducer(store, { key: "user", reducer: reducerUser.default });
          renderRoute(component);
        });
      },
      childRoutes: [
        {
          path: "",
          name: "organizationGeneralSettings",
          getComponent(nextState, cb) {
            const importModules = Promise.all([
              import("Reducers/organization")
            ]);

            const renderRoute = loadRoute(cb);

            importModules.then(([reducer, component]) => {
              injectReducer(store, {
                key: "organization",
                reducer: reducer.default
              });
              renderRoute(component);
            });
          }
        },
        {
          path: "accounts",
          name: "organizationAccounts",
          disabled: true,
          getComponent(nextState, cb) {
            const importModules = Promise.all([
              import("Reducers/organization"),
              import("./pages/organization/settings/Accounts")
            ]);

            const renderRoute = loadRoute(cb);

            importModules.then(([reducer, component]) => {
              injectReducer(store, {
                key: "organization",
                reducer: reducer.default
              });
              renderRoute(component);
            });
          }
        },
        {
          path: "security",
          name: "organizationSecurity",
          disabled: true,
          getComponent(nextState, cb) {
            const importModules = Promise.all([
              import("Reducers/organization"),
              import("./pages/organization/settings/Security")
            ]);

            const renderRoute = loadRoute(cb);

            importModules.then(([reducer, component]) => {
              injectReducer(store, {
                key: "organization",
                reducer: reducer.default
              });
              renderRoute(component);
            });
          }
        },
        {
          path: "security/setup",
          name: "organizationSecuritySetup",
          disabled: true,
          getComponent(nextState, cb) {
            const importModules = Promise.all([
              import("Reducers/organization"),
              import("./pages/organization/settings/SecuritySetup")
            ]);

            const renderRoute = loadRoute(cb);

            importModules.then(([reducer, component]) => {
              injectReducer(store, {
                key: "organization",
                reducer: reducer.default
              });
              renderRoute(component);
            });
          }
        },
        {
          path: "tokens",
          name: "organizationTokens",
          disabled: true,
          getComponent(nextState, cb) {
            const importModules = Promise.all([
              import("Reducers/organization"),
              import("./pages/organization/settings/ApiTokens")
            ]);

            const renderRoute = loadRoute(cb);

            importModules.then(([reducer, component]) => {
              injectReducer(store, {
                key: "organization",
                reducer: reducer.default
              });
              renderRoute(component);
            });
          }
        },
        {
          path: "ssh-keys",
          name: "organizationSshKeys",
          disabled: true,
          getComponent(nextState, cb) {
            const importModules = Promise.all([
              import("Reducers/organization"),
              import("Reducers/organization/settings/sshKey"),
              import("./pages/organization/settings/SshKeys")
            ]);

            const renderRoute = loadRoute(cb);

            importModules.then(([reducer, reducerSshKey, component]) => {
              injectReducer(store, {
                key: "organization",
                reducer: reducer.default
              });
              injectReducer(store, {
                key: "userSshKey",
                reducer: reducerSshKey.default
              });
              renderRoute(component);
            });
          }
        }
      ]
    },
    {
      path: "/:organizationId/billing",
      name: "organization-plan",
      disabled: true, // Always disable if it is a vendor URL.
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import("Reducers/project"),
          import("Reducers/subscription"),
          import("./pages/organization/Plan")
        ]);

        const renderRoute = loadRoute(cb);

        importModules.then(
          ([projectReducer, subscriptionReducer, component]) => {
            injectReducer(store, {
              key: "project",
              reducer: projectReducer.default
            });
            injectReducer(store, {
              key: "subscription",
              reducer: subscriptionReducer.default
            });
            renderRoute(component);
          }
        );
      }
    },
    {
      path: "/:organizationId/billing/history",
      name: "organization-billing",
      disabled: true,
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import("Reducers/project"),
          import("Reducers/organization/order"),
          import("./pages/organization/Billing")
        ]);

        const renderRoute = loadRoute(cb);

        importModules.then(([projectReducer, orderReducer, component]) => {
          injectReducer(store, {
            key: "project",
            reducer: projectReducer.default
          });
          injectReducer(store, {
            key: "order",
            reducer: orderReducer.default
          });
          renderRoute(component);
        });
      }
    },
    {
      path: "/:organizationId/billing/details",
      name: "billing-card",
      disabled: true,
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import("Reducers/project"),
          import("./pages/organization/BillingCard")
        ]);

        const renderRoute = loadRoute(cb);

        importModules.then(([reducer, component]) => {
          injectReducer(store, {
            key: "project",
            reducer: reducer.default
          });
          renderRoute(component);
        });
      }
    },
    {
      path: "/:organizationId/billing/details/address",
      name: "billing-address",
      disabled: process.env.VENDOR_URL,
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import("Reducers/project"),
          import("./pages/organization/BillingAddress")
        ]);

        const renderRoute = loadRoute(cb);

        importModules.then(([reducer, component]) => {
          injectReducer(store, {
            key: "project",
            reducer: reducer.default
          });
          renderRoute(component);
        });
      }
    },
    {
      path: "/:organizationId/:projectId/setup(/:brand/:step)",
      name: "project-setup",
      indexRoute: {
        getComponent(nextState, cb) {
          const importModules = Promise.all([
            import("Reducers/project"),
            import("./pages/Setup")
          ]);

          const renderRoute = loadRoute(cb);

          importModules.then(([projectReducer, component]) => {
            injectReducer(store, {
              key: "project",
              reducer: projectReducer.default
            });
            renderRoute(component);
          });
        }
      }
    },
    {
      path: "/:organizationId/:projectId",
      name: "project",
      indexRoute: {
        getComponent(nextState, cb) {
          const importModules = Promise.all([
            import("Reducers/project"),
            import("Reducers/environment/deployment"),
            import("Reducers/activity"),
            import("Reducers/project/region"),
            import("./pages/project/Detail")
          ]);

          const renderRoute = loadRoute(cb);

          importModules.then(
            ([
              projectReducer,
              environmentDeploymentReducer,
              activityReducer,
              regionReducer,
              component
            ]) => {
              injectReducer(store, {
                key: "project",
                reducer: projectReducer.default
              });
              injectReducer(store, {
                key: "activity",
                reducer: activityReducer.default
              });
              injectReducer(store, {
                key: "region",
                reducer: regionReducer.default
              });
              injectReducer(store, {
                key: "deployment",
                reducer: environmentDeploymentReducer.default
              });
              renderRoute(component);
            }
          );
        }
      },
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import("Reducers/project"),
          import("Reducers/environment"),
          import("./pages/project")
        ]);

        const renderRoute = loadRoute(cb);

        importModules.then(
          ([projectReducer, environmentReducer, component]) => {
            injectReducer(store, {
              key: "project",
              reducer: projectReducer.default
            });
            injectReducer(store, {
              key: "environment",
              reducer: environmentReducer.default
            });
            renderRoute(component);
          }
        );
      },
      childRoutes: [
        {
          path: "settings",
          name: "projectSettings",
          getComponent(nextState, cb) {
            const importModules = Promise.all([
              import("Reducers/project"),
              import("./pages/project/settings")
            ]);

            const renderRoute = loadRoute(cb);

            importModules.then(([reducer, component]) => {
              injectReducer(store, {
                key: "project",
                reducer: reducer.default
              });
              renderRoute(component);
            });
          },
          childRoutes: [
            {
              path: "",
              name: "projectBasics",
              getComponent(nextState, cb) {
                const importModules = Promise.all([import("Reducers/project")]);

                const renderRoute = loadRoute(cb);

                importModules.then(([reducer, component]) => {
                  injectReducer(store, {
                    key: "project",
                    reducer: reducer.default
                  });
                  renderRoute(component);
                });
              }
            },
            {
              path: "access",
              name: "projectAccess",
              getComponent(nextState, cb) {
                const importModules = Promise.all([
                  import("Reducers/project"),
                  import("Reducers/project/access"),
                  import("Reducers/environment/access"),
                  import("Reducers/user"),
                  import("./pages/project/Access")
                ]);

                const renderRoute = loadRoute(cb);

                importModules.then(
                  ([
                    projectReducer,
                    projectAccessReducer,
                    environmentAccessReducer,
                    userReducer,
                    component
                  ]) => {
                    injectReducer(store, {
                      key: "project",
                      reducer: projectReducer.default
                    });
                    injectReducer(store, {
                      key: "projectAccess",
                      reducer: projectAccessReducer.default
                    });
                    injectReducer(store, {
                      key: "user",
                      reducer: userReducer.default
                    });
                    injectReducer(store, {
                      key: "environmentAccess",
                      reducer: environmentAccessReducer.default
                    });
                    renderRoute(component);
                  }
                );
              }
            },
            {
              path: "variables",
              name: "projectVariables",
              getComponent(nextState, cb) {
                const importModules = Promise.all([
                  import("Reducers/project/settings/variable"),
                  import("./pages/project/settings/Variables")
                ]);

                const renderRoute = loadRoute(cb);

                importModules.then(([reducer, component]) => {
                  injectReducer(store, {
                    key: "projectVariable",
                    reducer: reducer.default
                  });
                  renderRoute(component);
                });
              }
            },
            // {
            //   path: "environment_types",
            //   name: "projectEnvironmentTypes",
            //   getComponent(nextState, cb) {
            //     const importModules = Promise.all([
            //       import("Reducers/project/settings/environmentType"),
            //       import("./pages/project/settings/EnvironmentTypes")
            //     ]);
            //
            //     const renderRoute = loadRoute(cb);
            //
            //     importModules.then(([reducer, component]) => {
            //       injectReducer(store, {
            //         key: "projectEnvironmentType",
            //         reducer: reducer.default
            //       });
            //       renderRoute(component);
            //     });
            //   }
            // },
            {
              path: "domains",
              name: "projectDomains",
              getComponent(nextState, cb) {
                const importModules = Promise.all([
                  import("Reducers/project/settings/domain"),
                  import("./pages/project/settings/Domains")
                ]);

                const renderRoute = loadRoute(cb);

                importModules.then(([reducer, component]) => {
                  injectReducer(store, {
                    key: "projectDomain",
                    reducer: reducer.default
                  });
                  renderRoute(component);
                });
              }
            },
            {
              path: "certificates",
              name: "projectCertificates",
              getComponent(nextState, cb) {
                const importModules = Promise.all([
                  import("Reducers/project/settings/certificate"),
                  import("./pages/project/settings/Certificates")
                ]);

                const renderRoute = loadRoute(cb);

                importModules.then(([reducer, component]) => {
                  injectReducer(store, {
                    key: "projectCertificate",
                    reducer: reducer.default
                  });
                  renderRoute(component);
                });
              }
            },
            {
              path: "deploy_key",
              name: "projectDeployKey",
              getComponent(nextState, cb) {
                const importModules = Promise.all([
                  import("Reducers/project"),
                  import("./pages/project/settings/DeployKey")
                ]);

                const renderRoute = loadRoute(cb);

                importModules.then(([reducer, component]) => {
                  injectReducer(store, {
                    key: "project",
                    reducer: reducer.default
                  });
                  renderRoute(component);
                });
              }
            }
          ]
        },
        {
          path: "plan",
          name: "projectPlan",
          disabled: true,
          getComponent(nextState, cb) {
            const importModules = Promise.all([
              import("Reducers/project"),
              import("Reducers/subscription"),
              import("./pages/project/Plan")
            ]);

            const renderRoute = loadRoute(cb);

            importModules.then(
              ([projectReducer, subscriptionReducer, component]) => {
                injectReducer(store, {
                  key: "project",
                  reducer: projectReducer.default
                });
                injectReducer(store, {
                  key: "subscription",
                  reducer: subscriptionReducer.default
                });
                renderRoute(component);
              }
            );
          }
        },
        {
          path: "integrations",
          name: "projectIntegrations",
          getComponent(nextState, cb) {
            const importModules = Promise.all([
              import("Reducers/project"),
              import("./pages/project/Integrations")
            ]);

            const renderRoute = loadRoute(cb);

            importModules.then(([reducer, component]) => {
              injectReducer(store, {
                key: "project",
                reducer: reducer.default
              });
              renderRoute(component);
            });
          }
        },
        {
          path: "/:organizationId/:projectId/:environmentId",
          name: "environment",
          indexRoute: {
            getComponent(nextState, cb) {
              const importModules = Promise.all([
                import("Reducers/environment"),
                import("Reducers/environment/deployment"),
                import("Reducers/activity"),
                import("./pages/environment/Detail")
              ]);

              const renderRoute = loadRoute(cb);

              importModules.then(
                ([
                  environmentReducer,
                  deploymentReducer,
                  activityReducer,
                  component
                ]) => {
                  injectReducer(store, {
                    key: "environment",
                    reducer: environmentReducer.default
                  });
                  injectReducer(store, {
                    key: "deployment",
                    reducer: deploymentReducer.default
                  });
                  injectReducer(store, {
                    key: "activity",
                    reducer: activityReducer.default
                  });
                  renderRoute(component);
                }
              );
            }
          },
          getComponent(nextState, cb) {
            const importModules = Promise.all([
              import("Reducers/environment"),
              import("./pages/environment")
            ]);

            const renderRoute = loadRoute(cb);

            importModules.then(([reducer, component]) => {
              injectReducer(store, {
                key: "environment",
                reducer: reducer.default
              });
              renderRoute(component);
            });
          },
          childRoutes: [
            {
              path: "actions",
              name: "environmentDetail",
              getComponent(nextState, cb) {
                const importModules = Promise.all([
                  import("Reducers/environment"),
                  import("Reducers/environment/deployment"),
                  import("./pages/environment/Detail")
                ]);

                const renderRoute = loadRoute(cb);

                importModules.then(
                  ([environmentReducer, deploymentReducer, component]) => {
                    injectReducer(store, {
                      key: "environment",
                      reducer: environmentReducer.default
                    });
                    injectReducer(store, {
                      key: "deployment",
                      reducer: deploymentReducer.default
                    });
                    renderRoute(component);
                  }
                );
              },
              indexRoute: {
                getComponent(nextState, cb) {
                  const importModules = Promise.all([
                    import("Reducers/environment"),
                    import("Reducers/environment/deployment"),
                    import("Containers/EnvironmentOverview")
                  ]);

                  const renderRoute = loadRoute(cb);

                  importModules.then(
                    ([environmentReducer, deploymentReducer, component]) => {
                      injectReducer(store, {
                        key: "environment",
                        reducer: environmentReducer.default
                      });
                      injectReducer(store, {
                        key: "deployment",
                        reducer: deploymentReducer.default
                      });
                      renderRoute(component);
                    }
                  );
                }
              },
              childRoutes: [
                {
                  path: "sync",
                  name: "environmentActionSync",
                  getComponent(nextState, cb) {
                    const importModules = Promise.all([
                      import("Reducers/environment"),
                      import("Reducers/environment/actions/sync"),
                      import("./pages/environment/actions/Synchronisation")
                    ]);

                    const renderRoute = loadRoute(cb);

                    importModules.then(
                      ([environmentReducer, syncReducer, component]) => {
                        injectReducer(store, {
                          key: "environment",
                          reducer: environmentReducer.default
                        });
                        injectReducer(store, {
                          key: "synchronisation",
                          reducer: syncReducer.default
                        });
                        renderRoute(component);
                      }
                    );
                  }
                },
                {
                  path: "merge",
                  name: "environmentActionMerge",
                  getComponent(nextState, cb) {
                    const importModules = Promise.all([
                      import("Reducers/environment"),
                      import("Reducers/environment/actions/merge"),
                      import("./pages/environment/actions/Merge")
                    ]);

                    const renderRoute = loadRoute(cb);

                    importModules.then(
                      ([environmentReducer, branchReducer, component]) => {
                        injectReducer(store, {
                          key: "environment",
                          reducer: environmentReducer.default
                        });
                        injectReducer(store, {
                          key: "merge",
                          reducer: branchReducer.default
                        });
                        renderRoute(component);
                      }
                    );
                  }
                },
                {
                  path: "backup",
                  name: "environmentActionBackup",
                  getComponent(nextState, cb) {
                    const importModules = Promise.all([
                      import("Reducers/environment"),
                      import("Reducers/environment/actions/backup"),
                      import("./pages/environment/actions/Backup")
                    ]);

                    const renderRoute = loadRoute(cb);

                    importModules.then(
                      ([environmentReducer, backupReducer, component]) => {
                        injectReducer(store, {
                          key: "environment",
                          reducer: environmentReducer.default
                        });
                        injectReducer(store, {
                          key: "backup",
                          reducer: backupReducer.default
                        });
                        renderRoute(component);
                      }
                    );
                  }
                },
                {
                  path: "branch",
                  name: "environmentActionBranch",
                  getComponent(nextState, cb) {
                    const importModules = Promise.all([
                      import("Reducers/environment"),
                      import("Reducers/environment/actions/branch"),
                      import("./pages/environment/actions/Branch")
                    ]);

                    const renderRoute = loadRoute(cb);

                    importModules.then(
                      ([environmentReducer, branchReducer, component]) => {
                        injectReducer(store, {
                          key: "environment",
                          reducer: environmentReducer.default
                        });
                        injectReducer(store, {
                          key: "branch",
                          reducer: branchReducer.default
                        });
                        renderRoute(component);
                      }
                    );
                  }
                }
              ]
            },
            {
              path: "settings",
              name: "environmentSettings",
              getComponent(nextState, cb) {
                const importModules = Promise.all([
                  import("./pages/environment/settings")
                ]);

                const renderRoute = loadRoute(cb);

                importModules.then(([component]) => {
                  renderRoute(component);
                });
              },
              indexRoute: {
                getComponent(nextState, cb) {
                  const importModules = Promise.all([
                    import("Reducers/environment"),
                    import("./pages/environment/settings/General")
                  ]);

                  const renderRoute = loadRoute(cb);

                  importModules.then(([reducer, component]) => {
                    injectReducer(store, {
                      key: "environment",
                      reducer: reducer.default
                    });
                    renderRoute(component);
                  });
                }
              },
              childRoutes: [
                {
                  path: "access",
                  name: "environmentAccess",
                  getComponent(nextState, cb) {
                    const importModules = Promise.all([
                      import("Reducers/environment/access"),
                      import("Reducers/user"),
                      import("./pages/environment/settings/Access")
                    ]);

                    const renderRoute = loadRoute(cb);

                    importModules.then(
                      ([environmentAccessReducer, userReducer, component]) => {
                        injectReducer(store, {
                          key: "environmentAccess",
                          reducer: environmentAccessReducer.default
                        });
                        injectReducer(store, {
                          key: "user",
                          reducer: userReducer.default
                        });
                        renderRoute(component);
                      }
                    );
                  }
                },
                {
                  path: "variables",
                  name: "environmentSettingsVariables",
                  getComponent(nextState, cb) {
                    const importModules = Promise.all([
                      import("Reducers/environment"),
                      import("Reducers/environment/settings/variable"),
                      import("./pages/environment/settings/Variables")
                    ]);

                    const renderRoute = loadRoute(cb);

                    importModules.then(
                      ([environmentReducer, variableReducer, component]) => {
                        injectReducer(store, {
                          key: "environmentVariable",
                          reducer: variableReducer.default
                        });
                        injectReducer(store, {
                          key: "environment",
                          reducer: environmentReducer.default
                        });
                        renderRoute(component);
                      }
                    );
                  }
                },
                {
                  path: "routes",
                  name: "environmentSettingsRoutes",
                  getComponent(nextState, cb) {
                    const importModules = Promise.all([
                      import("Reducers/environment"),
                      import("Reducers/environment/settings/route"),
                      import("./pages/environment/settings/Routes")
                    ]);

                    const renderRoute = loadRoute(cb);

                    importModules.then(
                      ([environmentReducer, routeReducer, component]) => {
                        injectReducer(store, {
                          key: "route",
                          reducer: routeReducer.default
                        });
                        injectReducer(store, {
                          key: "environment",
                          reducer: environmentReducer.default
                        });
                        renderRoute(component);
                      }
                    );
                  }
                }
              ]
            },
            {
              path: "backups",
              name: "environmentBackups",
              getComponent(nextState, cb) {
                const importModules = Promise.all([
                  import("Reducers/environment"),
                  import("Reducers/environment/deployment"),
                  import("Reducers/activity"),
                  import("./pages/environment/Backups")
                ]);

                const renderRoute = loadRoute(cb);

                importModules.then(
                  ([
                    environmentReducer,
                    deploymentReducer,
                    activityReducer,
                    component
                  ]) => {
                    injectReducer(store, {
                      key: "environment",
                      reducer: environmentReducer.default
                    });
                    injectReducer(store, {
                      key: "deployment",
                      reducer: deploymentReducer.default
                    });
                    injectReducer(store, {
                      key: "activity",
                      reducer: activityReducer.default
                    });
                    renderRoute(component);
                  }
                );
              }
            },
            {
              path: "services",
              name: "environmentServices",
              getComponent(nextState, cb) {
                const importModules = Promise.all([
                  import("./pages/environment/Services"),
                  import("Reducers/environment/deployment")
                ]);

                const renderRoute = loadRoute(cb);

                importModules.then(([component, reducer]) => {
                  injectReducer(store, {
                    key: "deployment",
                    reducer: reducer.default
                  });
                  renderRoute(component);
                });
              }
            }
          ]
        }
      ]
    },
    {
      path: "*",
      name: "notfound",
      getComponent(nextState, cb) {
        const importModules = Promise.all([import("./pages/NotFound")]);

        const renderRoute = loadRoute(cb);

        importModules.then(([component]) => {
          renderRoute(component);
        });
      }
    }
  ];

  return routeDefinitions.filter(route => !route.disabled);
}
