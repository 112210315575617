import { combineReducers } from "redux";
import { routerReducer } from "react-router-redux";

const makeRootReducer = asyncReducers =>
  combineReducers({
    routing: routerReducer,
    ...asyncReducers
  });

export default makeRootReducer;

export const injectReducer = (store, { key, reducer }) => {
  if (Object.hasOwnProperty.call(store.asyncReducers, key)) return;

  store.asyncReducers[key] = reducer;
  store.replaceReducer(makeRootReducer(store.asyncReducers));
};
