import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const StateIconLayout = styled.span`
  display: flex;
  align-items: center;
  height: 24px;
  width: 24px;
  box-sizing: border-box;
  padding: 2px;
  svg {
    width: 100%;
  }
`;

const SuccessStateIcon = ({ color = "#5f5e70" }) => {
  return (
    <StateIconLayout className="icon-success-wrapper">
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 20 20"
        style={{ enableBackground: "new 0 0 20 20" }}
        xmlSpace="preserve"
      >
        <path
          className="icon-success"
          d="M10,0C4.5,0,0,4.5,0,10s4.5,10,10,10s10-4.5,10-10S15.5,0,10,0z M3.7,10.7c-0.4-0.4-0.4-1,0-1.4
          c0.4-0.4,1-0.4,1.4,0L8,12.2l6.9-6.9c0.4-0.4,1-0.4,1.4,0c0.4,0.4,0.4,1,0,1.4l-7.6,7.6c-0.4,0.4-1,0.4-1.4,0L3.7,10.7z"
          fill={color}
        />
      </svg>
    </StateIconLayout>
  );
};

SuccessStateIcon.propTypes = {
  color: PropTypes.string
};

export default SuccessStateIcon;
