import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { Link } from "react-router";

import PlusIcon from "Icons/PlusIcon";

const LinkWrapper = styled.span`
  display: inline-block;
  a {
    background-color: ${props =>
      props.theme.buttonBg ? props => props.theme.buttonBg : "#4786ff"};
    color: ${props =>
      props.theme.buttonText ? props.theme.buttonText : "#fff"};
    border: none;
    border-radius: 2px;
    padding: 0px 24px;
    height: 30px;
    line-height: 30px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    display: inline-block;
    text-decoration: none;
    &:first-letter {
      text-transform: uppercase;
    }
    &:hover {
      background-color: ${props =>
        props.theme.buttonHover ? props.theme.buttonHover : "#1664FF"};
    }
    &:focus {
      border: 1px solid
        ${props =>
          props.theme.buttonHover ? props.theme.buttonHover : "#1664FF"};
      margin: -1px;
      line-height: 30px;
      box-shadow: 0 0 2px
        ${props =>
          props.theme.buttonHover ? props.theme.buttonHover : "#1664FF"};
      outline: none;
    }
    &:active {
      margin: 0;
      box-shadow: none;
      border: none;
      background-color: ${props =>
        props.theme.buttonHover ? props.theme.buttonHover : "#1664FF"};
    }
    &.secondary {
      background: transparent;
      color: #4a495e;
      &:hover {
        background-color: #ededed;
      }
      &:focus {
        margin-left: inherit;
      }
      &:active {
        background-color: #ededed;
      }
    }
    &.outline {
      background: transparent;
      color: ${props => props.theme.links};
      border: 1px solid #c9d0e4;
      margin: -1px;
      line-height: 30px;
      &:hover {
        background-color: ${props => props.theme.buttonHover};
        color: ${props => props.theme.buttonText};
        border: none;
        margin: 0;
      }
      &:focus {
        border: 1px solid ${props => props.theme.buttonHover};
        margin: -1px;
        line-height: 30px;
        box-shadow: 0 0 2px ${props => props.theme.buttonHover};
        outline: none;
      }
      &:active {
        margin: 0;
        box-shadow: none;
        border: none;
        background-color: ${props => props.theme.buttonHover};
      }
    }
    &:disabled {
      opacity: 0.8;
      cursor: not-allowed;
    }
    &.add-link {
      dislay: flex;
      align-items: center;
      padding-left: 16px;
      padding-right: 24px;
      span.text {
        display: inline-block;
        &:first-letter {
          text-transform: capitalize;
        }
      }
      span.icon {
        width: 16px;
        display: inline-flex;
        align-items: center;
        height: 30px;
        svg {
          width: 10px;
          height: auto;
        }
      }
      &:focus {
        height: 30px;
      }
    }
  }
`;

class ButtonLink extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    const {
      to,
      onClick,
      text,
      primary = false,
      addLink = false,
      external = false,
      blank = true,
      mailLink = false,
      className,
      id
    } = this.props;

    return (
      <LinkWrapper className="link-button" {...this.props}>
        {external || mailLink ? (
          <a
            id={id}
            className={`button${addLink ? " add-link" : ""}${
              className ? " " + className : ""
            }`}
            href={to}
            role="button"
            tabIndex="0"
            aria-label={text}
            target={mailLink || blank ? "_blank" : ""}
            rel={mailLink || blank ? "noopener noreferrer" : ""}
          >
            {addLink ? (
              <React.Fragment>
                <span className="icon">
                  <PlusIcon />
                </span>
                <span className="text">{text}</span>
              </React.Fragment>
            ) : (
              text
            )}
          </a>
        ) : (
          <Link
            id={id}
            className={`button${primary ? " primary" : ""}${
              addLink ? " add-link" : ""
            }${className ? " " + className : ""}`}
            to={to}
            onClick={
              onClick
                ? e => {
                    e.preventDefault();
                    onClick();
                  }
                : () => {}
            }
            role="button"
            tabIndex="0"
            href={to}
            aria-label={text}
          >
            {addLink ? (
              <React.Fragment>
                <span className="icon">
                  <PlusIcon />
                </span>
                <span className="text">{text}</span>
              </React.Fragment>
            ) : (
              text
            )}
          </Link>
        )}
      </LinkWrapper>
    );
  }
}

ButtonLink.propTypes = {
  to: PropTypes.string,
  onClick: PropTypes.func,
  text: PropTypes.string.isRequired,
  primary: PropTypes.bool,
  addLink: PropTypes.bool,
  external: PropTypes.bool,
  blank: PropTypes.bool,
  mailLink: PropTypes.bool,
  className: PropTypes.string,
  id: PropTypes.string
};

export default ButtonLink;
