import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import logger from "Libs/logger";

import Heading1 from "Components/styleguide/Heading1";
import sadMoonMan from "Images/sad-moon-man.svg";
import stars from "Images/stars.svg";
import ButtonLink from "Components/ButtonLink";
import CopyButton from "Components/CopyButton";
import PlatformWordmark from "Components/icons/PlatformWordmark";
import { Link } from "react-router";

const ErrorMessagePageWrapper = styled.div`
  background: #1a192b;
  color: #fff;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  .header-wrapper {
    margin: 5vw 10vw 0 10vw;
    svg {
      height: 38px;
      width: auto;
    }
  }
  h1 {
    margin-bottom: 10px;
    color: #fff;
  }
  p {
    font-size: 15px;
  }
  .moon-man {
    position: absolute;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    right: 0;
    bottom: 0;
    width: 100%;
  }
  .moon-man img {
    width: 100%;
    height: auto;
    max-width: 100%;
    max-height: 100%;
  }
  .text-wrapper {
    width: 80vw;
    margin: 10vw 10vw 0 10vw;
    max-height: 60vh;
    z-index: 2;
    position: relative;
    .code {
      margin: 40px 0 20px;
      border-radius: 4px;
      overflow: hidden;
      pre {
        font-family: AndaleMono;
        font-size: 12px;
        line-height: 14px;
        background: #f5f7fa;
        max-height: 300px;
        color: #1a192b;
        width: 100%;
        max-width: 100%;
        box-sizing: border-box;
        white-space: pre;
        margin: 0;
        padding: 9px 16px;
        text-align: left;
        overflow: scroll;
      }
    }
  }
  .buttons {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .copy-to-clipboard:hover {
      background-color: #1664FF;
    }
    .button-wrapper {
      margin-top: 60px;
    }
    a {
      border-radius: 4px;
      padding: 0 30px;
      &.button {
        line-height: 40px;
        height: 40px;
      }
      &.outline {
        line-height: 38px;
        height 40px;
        box-sizing: border-box;
        margin: 0 0 0 20px;
        &:focus {
          border: 1px solid #1664FF;
          box-shadow: 0px 0px 2px #1664FF;
        }
        &:hover {
          padding: 0 31px;
          background: #1664FF;
          border: none;
        }
      }
    }
  }
  @media (min-width: 900px) {
    .text-wrapper {
      width: 45vw;
      margin: 10vh 0 0 10vw;
      max-height: 80vh;
    }
    .moon-man {
      width: 59.375%;
    }
  }
`;

const CodeWrapper = styled.pre`
  padding: 32px;
  max-width: 100%;
  scroll: auto;
  overflow: hidden;
`;

class AppErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      error: {},
      info: {}
    };
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({
      hasError: true,
      error: error,
      info: info
    });
    const spinner = document.getElementById("pre-loading");
    const fallback = document.getElementById("fallback");
    if (spinner) {
      spinner.remove();
    }
    if (fallback) {
      fallback.remove();
    }

    logger(error);
  }

  render() {
    const {
      hasError,
      error: { stack }
    } = this.state;
    if (hasError) {
      // You can render any custom fallback UI
      return (
        <ErrorMessagePageWrapper
          style={{
            background: `#1a192b url(${stars}) no-repeat center center`
          }}
        >
          <div className="header-wrapper">
            <Link className="logo" to={"/"}>
              <PlatformWordmark alt="logo" color="#ffffff" size={122} />
            </Link>
          </div>
          <div className="text-wrapper">
            <Heading1>Oops, we've had an error</Heading1>
            <p>
              The following error broke our application before it could load.
            </p>
            <div className="code">
              <CodeWrapper>{stack}</CodeWrapper>
            </div>
            <div className="buttons">
              <CopyButton
                text={stack}
                theme={{ buttonText: "#ffffff", buttonBg: "#4786ff" }}
              />
              <div className="button-wrapper">
                <ButtonLink text="Back to projects" to="/projects" />
                <ButtonLink
                  to="https://accounts.platform.sh/support"
                  external={true}
                  text="Report"
                  className="outline"
                />
              </div>
            </div>
          </div>
          <div className="moon-man">
            <img src={sadMoonMan} alt="sad moon man" />
          </div>
        </ErrorMessagePageWrapper>
      );
    }
    return this.props.children;
  }
}

AppErrorBoundary.propTypes = {
  children: PropTypes.node
};

export default AppErrorBoundary;
