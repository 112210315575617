import React from "react";
import copyToClipboard from "copy-to-clipboard";
import styled, { keyframes } from "styled-components";
import PropTypes from "prop-types";

import CopyIcon from "Icons/CopyIcon";
import SuccessStateIcon from "Icons/SuccessStateIcon";

const LayoutWrapper = styled.span`
  display: inline-block;
  position: relative;
  &.link-style {
    button {
      background: transparent;
      color: ${props => props.theme.links};
      svg > g > g {
        fill: ${props => props.theme.links};
      }
      &:hover {
        background: ${props => props.theme.buttonBg};
        color: ${props => props.theme.buttonText};
        svg > g > g {
          fill: ${props => props.theme.buttonText};
        }
      }
    }
  }
`;

const popin = keyframes`
  0% {
    opacity: 0;
    height: 10px;
    width: 10px;
  }
  20% {
    height: 24px;
    width: 24px;
    opacity: 1;
  }
  80% {
    height: 24px;
    width: 24px;
    opacity: 1;
  }
  100% {
    height: 10px;
    width: 10px;
    opacity: 0;
  }
`;

const fadeInOut = keyframes`
  0% {
    background: rgba(22, 100, 255, 0);
  }
  30% {
    background: rgba(22, 100, 255, 1);
  }
  70% {
    background: rgba(22, 100, 255, 1);
  }
  100% {
    background: rgba(22, 100, 255, 0);
  }
`;

const Layout = styled.button`
  color: ${props => props.theme.buttonText};
  background: ${props => props.theme.buttonBg};
  font-size: 14px;
  line-height: 32px;
  height: 32px;
  padding: 0 16px;
  border-radius: 2px;
  border: none;
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  margin: 0 !important;
  span {
    display: inline-block;
    &:first-letter {
      text-transform: uppercase;
    }
  }
  svg {
    margin-right: 16px;
    width: 16px;
    height: auto;
    > g > g {
      fill: ${props => props.theme.buttonText};
    }
  }
  .copied-message {
    position: absolute;
    color: ${props => props.theme.links};
    background: transparent;
    width: auto;
    outline: none;
    svg {
      max-height: 100%;
    }
  }
  &:hover,
  &:hover .copied-message {
    background-color: ${props => props.theme.buttonHover};
    svg > g > g {
      fill: ${props => props.theme.buttonText};
    }
  }
  &:focus {
    border: 1px solid ${props => props.theme.buttonHover};
    margin: 0 -1px !important;
    box-shadow: 0 0 2px ${props => props.theme.buttonHover};
    outline: none;
    line-height: 38px;
  }
  &:active {
    box-shadow: none;
    border: none;
    margin: 0 !important;
    background-color: ${props => props.theme.buttonHover};
  }
`;

const Info = styled.span`
  font-size: 10px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${props => props.theme.buttonBg};
  line-height: 40px;
  height: 40px;
  border-radius: 3px;
  color: ${props => props.theme.buttonText};
  font-weight: bold;
  animation: ${fadeInOut} 0.4s linear 1;
  background: rgba(71, 134, 255, 0);
  width: 100%;
  > span {
    opacity: 0;
    border-radius: 100%;
    display: inline-flex;
    animation: ${popin} 0.4s linear 1;
    box-sizing: border-box;
  }
  svg {
    width: 100%;
    height: 100%;
  }
`;

class CopyButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.copy = this.copy.bind(this);
  }

  componentWillUnmount() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  }

  copy(link) {
    copyToClipboard(link);

    this.setState({
      copied: true
    });

    this.timer = setTimeout(
      () =>
        this.setState({
          copied: false
        }),
      500
    );
  }

  render() {
    const { text, className, theme } = this.props;
    const { copied } = this.state;

    return (
      <LayoutWrapper className={className ? className : ""}>
        <Layout
          type="button"
          className="copy-to-clipboard"
          onClick={() => {
            this.copy(text);
            document.activeElement.blur();
          }}
          theme={theme}
        >
          <CopyIcon />
          <span>Copy</span>
        </Layout>
        {copied && (
          <Info className="copied-message">
            <SuccessStateIcon color="#fff" />
          </Info>
        )}
      </LayoutWrapper>
    );
  }
}

CopyButton.propTypes = {
  text: PropTypes.string,
  className: PropTypes.string,
  theme: PropTypes.object
};

export default CopyButton;
